//--------------------------------------------------------------
// GOOGLE RECAPTCHA
//--------------------------------------------------------------

const siteKey = '6Lf7HrsUAAAAABpEAHHH6ny2IoEqzXWTg9o89Os1';

const captchaField = document.querySelector('#g-recaptcha-response');

if (captchaField) {
	grecaptcha.ready(function() {
		grecaptcha.execute(`${siteKey}`, { action: 'homepage' }).then(function(token) {
			captchaField.value = token;
		});
	});
}
